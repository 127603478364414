$theme__base--color: #003d7c;
$theme__secondary--color: #e8e8e8;
$theme--accent-color: #393939;
$theme--accent-color--hover: #ff7800;
$theme__secondary--accent-color: #003d7c;
$theme__secondary--accent-color--hover: #ff7800;
$theme-light: #F7F7F7;
$theme--grey-lighter: #EFEFED;
$theme--grey-light: #F1F1F1;
$theme--grey-middle: #666;
$theme--grey-dark: #41474C;
$theme--nav-color: $theme__base--color;
$theme--footer-background-grey: #F1F1F1;
$theme--footer-background-dark-grey: #D1D1D3;
$theme--special-color: #ff7800;
$theme--border--dark-color: #666;

	//
//  Color variables
//  _____________________________________________

$color-white: #fff !default;
$color-black: #000 !default;
$border-grey-color: #e1e1e1;
$color-dark-gray: #666;
$color-light-gray: #a1a1a1;
$color-gray19: $theme--grey-dark !default;
$color-gray20: $theme--grey-dark !default;
$color-gray34: $theme--grey-dark !default;
$color-gray40: $theme--grey-dark !default;
$color-gray52: $theme--grey-dark !default;
$color-gray55: $theme--grey-dark !default;
$color-gray56: $theme--grey-dark !default;
$color-gray60: $theme--grey-dark !default;
$color-gray62: $theme--grey-dark !default;
$color-gray64: $theme--grey-dark !default;
$color-gray68: $theme--grey-light !default;
$color-gray76: $theme--grey-light !default;
$color-gray78: $theme--grey-light !default;
$color-gray79: $theme--grey-light !default;
$color-gray80: $theme--grey-lighter !default;
$color-gray82: $theme--grey-lighter !default;
$color-gray89: $theme--grey-lighter !default;
$color-gray90: $theme--grey-lighter !default;
$color-gray91: $theme--grey-lighter !default;
$color-gray92: $theme-light !default;
$color-gray94: $theme-light !default;
$color-gray95: $theme-light !default;
$color-white-smoke: $theme--grey-lighter !default;
$color-white-dark-smoke: $theme--grey-lighter !default;
$color-white-fog: $theme--grey-lighter !default;

$color-gray-light0: $theme--grey-lighter !default;
$color-gray-light01: $theme--grey-lighter !default;
$color-gray-light1: $theme--grey-lighter !default;
$color-gray-light2: $theme--grey-lighter !default;
$color-gray-light3: $theme--grey-lighter !default;
$color-gray-light4: $theme--grey-lighter !default;
$color-gray-light5: $theme--grey-lighter !default;
$color-gray-light6: $theme--grey-lighter !default;

$color-gray-middle1: $theme--grey-lighter !default;
$color-gray-middle2: $theme--grey-lighter !default;
$color-gray-middle3: #7e807e !default;

$color-gray-darken0: $theme--grey-lighter !default;
$color-gray-darken1: $theme--grey-lighter !default;
$color-gray-darken2: $theme--grey-middle !default;
$color-gray-darken3: $theme--grey-dark !default;
$color-gray-darken4: $theme--grey-dark !default;

$color-red9: #ff0101 !default;
$color-red10: $theme__secondary--accent-color !default;
$color-red11: $theme__secondary--accent-color !default;
$color-red12: $theme__secondary--accent-color !default;

$color-orange-red1: $theme__secondary--accent-color !default;
$color-orange-red2: $theme__secondary--accent-color !default;
$color-orange-red3: $theme__secondary--accent-color !default;
$color-orange-red4: $theme__secondary--accent-color !default;

$color-dark-green1: $theme--accent-color !default;

$color-blue1: $theme--accent-color !default;
$color-blue2: $theme--accent-color !default;
$color-sky-blue1: $theme--accent-color !default;

$color-pink1: #fae5e5 !default;
$color-dark-pink1: #800080 !default;

$color-brownie1: #6f4400 !default;
$color-brownie-light1: #c07600 !default;

$color-yellow-light1: #fdf0d5 !default;
$color-yellow-light2: #ffee9c !default;
$color-yellow-light3: #d6ca8e !default;


//
//  Color nesting
//  ---------------------------------------------

$primary__color: $theme__base--color;
$primary__color__dark: darken($primary__color, 35%) !default; // #000
$primary__color__darker: darken($primary__color, 13.5%) !default; // #111
$primary__color__lighter: $theme--grey-middle !default; // #7d7d7d
$primary__color__light: lighten($primary__color, 45%) !default; // #a6a6a6

$secondary__color: $theme--grey-lighter !default;
$secondary__color__light: lighten($secondary__color, 5%) !default;

$page__background-color: $color-white !default;
$panel__background-color: darken($page__background-color, 6%) !default;

$active__color: $color-orange-red1 !default;
$error__color: $color-red10 !default;